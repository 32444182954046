import React from "react";

const Information = () => {
  return <div>
    <h2>Welcome</h2>
    <p>
      This space provides a set of utilities for various engineering calculations. 
      The utilities include tools for calculating corner distance, counter sink depth, 
      cutting speed, rigid tapping, and more. Each utility is designed to be user-friendly 
      and efficient, providing accurate results for your engineering needs.
    </p>
  </div>;
};

export default Information;
