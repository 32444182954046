import React, { useState, useMemo, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../assets/css/business-loan-calculator.css";

const StripWidthCalculator = () => {
  const [type, setType] = useState("");
  const [diameter, setDiameter] = useState("");
  const [thickness, setThickness] = useState("");
  const [passes, setPasses] = useState("");
  const [designType, setDesignType] = useState("");

  const [resultVisible, setResultVisible] = useState(false);
  const [resultValues, setResultValues] = useState({
    high: "",
    low: "",
    laser: "",
    tig: "",
  });
  /************************* */

  // function display(id, value) {
  //   if (value != "N/A") {
  //     value = value.toFixed(4);
  //   }
  //   $(id).html(value);
  // }
  /************************* */

  const allFieldsCompleted = () => {
    var pi = 3.1415926536;
    var d2Offsets = {
      1: -0.012,
      2: -0.006,
      3: 0.0,
      4: 0.006,
      5: 0.012,
      6: 0.018,
    };

    const material = type;
    const diameterValue = parseFloat(diameter);

    const thicknessValue = parseFloat(thickness);
    const passesValue = parseFloat(passes);
    // const design = parseFloat(designType);
    const designValue = designType; // No need to parse as it is used for comparison

    let hFr = "NA";
    let lFr = "NA";
    let las = "NA";
    let tig = "NA";

    var e7 = 0.0; // magic value, it shall be named for the spreadsheet cell
    var d2 = d2Offsets[passesValue]; // magic value, it shall be named for the spreadsheet cell

    if (designValue === "reverse") {
      e7 = thicknessValue * 0.2;
    }

    if (material == "aluminum") {
      hFr = diameter * pi - 1.17 * thickness + d2 - e7;
      lFr = hFr;
      las = diameter * pi - 2.1208 * thickness + d2 - e7;
      tig = las;
    } else if (material == "brass") {
      hFr = diameter * pi + 0.1 * diameter - 0.4 * thickness + d2 - e7;
      lFr = hFr;
      las = diameter * pi - 2.1208 * thickness + d2 - e7;
      tig = las;
    } else if (material == "copper") {
      hFr = diameter * pi + 0.1 * diameter - 0.4 * thickness + d2 - e7;
      lFr = hFr;
      las = diameter * pi - 2.1208 * thickness + d2 - e7;
      tig = las;
    } else if (material == "stainless") {
      hFr = diameter * pi - 1.3208 * thickness + d2 - e7;
      lFr = hFr;
      las = (diameter - 0.5 * thickness) * pi - 0.55 * thickness + d2 - e7;
      tig = diameter * pi - 2.1208 * thickness + d2 - e7;
    } else if (material == "steel") {
      hFr = diameter * pi - 1.5117 * thickness + 0.006 + d2 - e7;
      lFr = hFr;
      las = diameter * pi - 2.1208 * thickness + d2 - e7;
      tig = las;
    } else if (material == "titanium") {
      las = diameter * pi - 2.1208 * thickness + d2 - e7;
      tig = las;
    }

    // Only call toFixed if the values are numbers
    setResultValues({
      high: isNaN(hFr) ? hFr : hFr.toFixed(4),
      low: isNaN(lFr) ? lFr : lFr.toFixed(4),
      laser: isNaN(las) ? las : las.toFixed(4),
      tig: isNaN(tig) ? tig : tig.toFixed(4),
    });

    setResultVisible(true);
  };

  useEffect(() => {
    if (type && diameter && thickness && passes && designType) {
      allFieldsCompleted();
    }
  }, [type, diameter, thickness, passes, designType]);

  return (
    <div className="loan-calculator material-calculator">
      <Helmet>
        <title>Strip Width Calculator - Integral Software Tools</title>
        <meta
          name="description"
          content="The Strip Width Calculator helps you calculate tube and pipe strip.All information is an estimate only and should be treated as such. Due to different variables on different mills, the width(s) calculated are strictly approximate and should be proven out before ordering an abundant supply."
        />
      </Helmet>
      <h3>Tube and Pipe Strip Calculator</h3>
      <p>
        All Dimensions are in Inches.
        <br />
        Use the "Tab" key to move between fields.
      </p>

      <p className="blue">All Variables are in Blue</p>

      <label>
        Choose Type of Material:
        <select
          name="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <option value="">Choose an Option</option>
          <option value="aluminum">Aluminium</option>
          <option value="steel">Steels</option>
          <option value="titanium">Titunium</option>
          <option value="brass">Brass</option>
          <option value="copper">Copper</option>
          <option value="stainless">Stainless Steel</option>
        </select>
      </label>

      <br />
      <label>
        Diameter at Welder (Inches):
        <input
          type="number"
          name="diameter"
          value={diameter}
          onChange={(e) => setDiameter(e.target.value)}
          step="0.01"
        />
      </label>
      <br />
      <label>
        Gauge Thickness (Inches):
        <input
          type="number"
          name="thickness"
          value={thickness}
          onChange={(e) => setThickness(e.target.value)}
          step="0.01"
        />
      </label>
      <br />
      <label>
        Number of Fin Passes:
        <select
          name="passes"
          value={passes}
          onChange={(e) => setPasses(e.target.value)}
        >
          <option value="">Choose an Option</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
        </select>
      </label>
      <br />
      <label>
        Choose Design Type:
        <select
          name="designType"
          value={designType}
          onChange={(e) => setDesignType(e.target.value)}
        >
          <option value="">Choose an Option</option>
          <option value="1">Conventional</option>
          <option value="2">Edgeform/Versatile Edgeform</option>
          <option value="3">Reverse Bend/"W" Style</option>
        </select>
      </label>
      <br />

      <div className="row">
        <strong>Estimated Strip Width (Inches):</strong>
        <br />
        <div className="col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          High Frequency
        </div>
        <div className="col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          Low Frequency
        </div>
        <div className="col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
          Laser
        </div>
        <div className="col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">TIG</div>
      </div>
      <br />
      {resultVisible && (
        <div className="row" id="result">
          <div className="col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            {resultValues.high}
          </div>
          <div className="col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            {resultValues.low}
          </div>
          <div className="col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            {resultValues.laser}
          </div>
          <div className="col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
            {resultValues.tig}
          </div>
        </div>
      )}
      <div className="row disclaimer-web">
        <p className="red">
          <strong>Disclaimer</strong>
        </p>
        <p>
          The above information is an estimate only and should be treated as
          such. Due to different variables on different mills, the width(s)
          calculated are strictly approximate and should be proven out before
          ordering an abundant supply.
        </p>
      </div>
    </div>
  );
};

export default StripWidthCalculator;
