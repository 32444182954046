import React from 'react';
import PropTypes from 'prop-types';
import MathJax from "react-mathjax2";

const MathFormula = ({ formula }) => {
  return (
    <MathJax.Context input="tex">
      <div>
        <MathJax.Node>{formula}</MathJax.Node>
      </div>
    </MathJax.Context>
  );
};

MathFormula.propTypes = {
  formula: PropTypes.string.isRequired,
};

export default MathFormula;
