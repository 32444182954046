import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Section from "../components/common/Section";
import { Redirect, Route, Switch, Link } from "react-router-dom";
import Logo from "../components/common/Logo";
import CornerDistance from "../components/Calculators/CornerDistance";
import CuttingSpeed from "../components/Calculators/CuttingSpeed";
import RigidTapping from "../components/Calculators/RigidTapping";
import CounterSinkDepth from "../components/Calculators/CounterSinkDepth";
import Information from "../components/Calculators/Information";
import QrCodeInspector from "../components/QrCodeScanning/QrCodeInspector";
import BoldUnicode from "../components/SocialMediaTools/TextToUnicodeBold";
import BusinessLoanCalculator from "../components/Calculators/BusinessLoanCalculator";
import StripWidthCalculator from "../components/Calculators/StripWidthCalculator";

const Home = () => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={2} md={2} lg={2} xl={2} className="col-xxl-3">
          <div className="side-nav">
            <Logo />
            <ul>
              <li>
                <Link to="/cornerdistance">Corner Distance</Link>
              </li>
              <li>
                <Link to="/countersink-depth">Counter Sink Depth</Link>
              </li>
              <li>
                <Link to="/cuttingspeed">Cutting Speed</Link>
              </li>
              <li>
                <Link to="/rigidtapping">Rigid Tapping</Link>
              </li>
              <li>
                <Link to="/business-loan-calculator">
                  Business Loan Calculator
                </Link>
              </li>
              <li>
                <Link to="/qr-inspector">QR Code and Barcode Inspector</Link>
              </li>
              <li>
                <Link to="/bold-unicode">Marketing - Bold Unicode</Link>
              </li>
              <li>
                <Link to="/strip-width-calculator">Strip Width Calculator</Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col sm={10} md={10} lg={10} xl={10} className="col-xxl-8">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-5">
              <Switch>
                <Route path={`/`} exact component={Information} />
                <Route
                  path={`/cornerdistance`}
                  exact
                  component={CornerDistance}
                />
                <Route
                  path={`/countersink-depth`}
                  exact
                  component={CounterSinkDepth}
                />
                <Route path={`/cuttingspeed`} exact component={CuttingSpeed} />
                <Route path={`/rigidtapping`} exact component={RigidTapping} />
                <Route
                  path={`/business-loan-calculator`}
                  exact
                  component={BusinessLoanCalculator}
                />
                <Route
                  path={`/qr-inspector`}
                  exact
                  component={QrCodeInspector}
                />
                <Route path={`/bold-unicode`} exact component={BoldUnicode} />
                <Route
                  path={`/strip-width-calculator`}
                  exact
                  component={StripWidthCalculator}
                />

                <Redirect to="/errors/404" />
              </Switch>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default Home;
