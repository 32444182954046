import React, { Fragment, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Divider from "../common/Divider";
import MathFormula from "../common/MathFormula";

const CounterSinkDepth = () => {
  const [number1, setNumber1] = useState();
  const [number2, setNumber2] = useState();
  const calculation = useMemo(() => {
    const diameter = parseFloat(number1);
    const angle = parseFloat(number2);
    const result = (
      diameter /
      2 /
      Math.tan(((Math.PI / 180) * angle) / 2)
    ).toFixed(6);

    if (isNaN(result)) return "";
    else return result;
  }, [number1, number2]);

  return (
    <Fragment>
      <Helmet>
        <title>Countersink Depth Calculator - Integral Software Tools</title>
        <meta name="description" content="Calculate the z-depth for a given diameter and angle of countersink. This is used in stock cutting, turning and drilling." />
      </Helmet>
      <h1>Countersink Depth Calculator</h1>
      <br />
      <FormGroup>
        <Row className="align-items-center">
          <Col sm="6" className="align-self-center">
            <Label className="m-0" for="number1">
              Diameter (in any unit, <b>total</b> will be in the same unit)
            </Label>
          </Col>
          <Col sm="6">
            <Input
              autoFocus
              id="number1"
              name="number1"
              type="number"
              className="text-end"
              value={number1}
              onChange={(e) => {
                setNumber1(e.target.value);
              }}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row className="align-items-center">
          <Col sm="6" className="align-self-center">
            <Label className="m-0" for="number2">
              Angle (degrees)
            </Label>
          </Col>
          <Col sm="6">
            <Input
              id="number2"
              name="number2"
              type="number"
              className="text-end"
              value={number2}
              onChange={(e) => {
                setNumber2(e.target.value);
              }}
            />
          </Col>
        </Row>
      </FormGroup>
      <Divider />
      <Row className="align-items-center">
        <Col sm="6" className="align-self-center">
          <h6>Total</h6>
        </Col>
        <Col sm="6">
          <h3 style={{ textAlign: "end" }}>{calculation}</h3>
        </Col>
      </Row>
      <Divider />
      <FormGroup>
        <p style={{ textAlign: "justify" }}>
          The Countersink Depth Calculator helps you determine the required
          z-depth for a given diameter and angle of countersink. Enter the major
          diameter and angle of the countersink to calculate the theoretical
          point depth. The result will provide the depth dimension for the
          specified diameter.
        </p>
        <p style={{ textAlign: "justify" }}>
          Note: The actual cut depth may be slightly deeper due to imperfections
          in the countersink tool’s point. To avoid cutting too deep, be sure to
          adjust your numbers accordingly.
        </p>
        <MathFormula formula={'\\text{Depth} = \\frac{\\text{Diameter}}{2 \\cdot \\tan\\left(\\frac{\\text{Angle}}{2}\\right)}'} />
      </FormGroup>
    </Fragment>
  );
};

export default CounterSinkDepth;
