import React, { Fragment, useMemo, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import Divider from "../common/Divider";

const CornerDistance = () => {
  const [number1, setNumber1] = useState();
  const [selectedOption, setSelectedOption] = useState(0);
  const handleSelectChange = (e) => {
    console.log("target value:", e.target.value);
    if (e.target.value === "square") setSelectedOption(0);
    else setSelectedOption(1);
  };

  const calculation1 = useMemo(() => {
    if (selectedOption === 0) {
      //this condition is for the square
      const hold_ac = parseFloat(number1);
      const hold2_ac = hold_ac * 1.414214;
      const result_ac = hold2_ac.toFixed(4);
      if (isNaN(result_ac)) return "";
      else return result_ac;
    } else {
      //this condition is for the hexagon
      const hold_ac = parseFloat(number1);
      const result_ac = ((hold_ac / 2 / 0.86603) * 2).toFixed(4);
      return result_ac;
    }
  }, [number1, selectedOption]);

  const calculation2 = useMemo(() => {
    if (selectedOption === 0) {
      //this condition is for the square
      const hold_ac = parseFloat(number1);
      const area_ac = (hold_ac * hold_ac).toFixed(4);
      if (isNaN(area_ac)) return "";
      else return area_ac;
    } else {
      //this condition is for the hexagon
      const hold_ac = parseFloat(number1);
      const hold2_ac = (hold_ac / 2) * 0.57735;
      const hold3_ac = (hold_ac / 2) * hold2_ac * 6;
      const area_ac = hold3_ac.toFixed(4);
      return area_ac;
    }
  }, [number1, selectedOption]);

  let description =
    "Use this calculator to determine the distance across corners for square and hexagonal stock. " +
  "This helps you find the appropriate tube diameter for fitting the stock. " +
  "Simply enter the measurement across the flat of the square or hexagon, and select the corresponding shape. " +
  "The calculator will provide the distance across the corners and the area of the section.";
  return (
    <Fragment>
      <Helmet>
        <title>Corner Distance Calculator - Integral Software Tools</title>
        <meta name="description" content={description} />
      </Helmet>
      <h1>Corner Distance Calculator</h1>
      <br />
      <FormGroup>
        <Row className="align-items-center">
          <Col sm="6" className="align-self-center">
            <Label className="m-0" for="number1">
              Distance across flats
            </Label>
          </Col>
          <Col sm="6">
            <Input
              autoFocus
              id="number1"
              name="number1"
              type="number"
              className="text-end"
              value={number1}
              onChange={(e) => {
                if (e.target.value === "") {
                  return setNumber1(0);
                }
                setNumber1(e.target.value);
              }}
            />
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Row className="align-items-center">
          <Col sm="6" className="align-self-center">
            <Label className="m-0" for="selectedOption">
              Dimensions
            </Label>
          </Col>
          <Col sm="6">
            <FormGroup>
              <div>
                <Label>
                  <Input
                    name="radio1"
                    type="radio"
                    value="square"
                    onChange={handleSelectChange}
                  />
                  Square
                </Label>
              </div>
              <div>
                <Label>
                  <Input
                    name="radio1"
                    type="radio"
                    value="hexagon"
                    onChange={handleSelectChange}
                  />
                  Hexagon
                </Label>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </FormGroup>
      <Divider />
      <Row className="align-items-center">
        <Col sm="6" className="align-self-center">
          <h6>Distance across corners</h6>
        </Col>
        <Col sm="6">
          <h3 style={{ textAlign: "end" }}>{calculation1}</h3>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col sm="6" className="align-self-center">
          <h6>Area of section</h6>
        </Col>
        <Col sm="6">
          <h3 style={{ textAlign: "end" }}>{calculation2}</h3>
        </Col>
      </Row>
      <Divider />
      <FormGroup>
        <p style={{ textAlign: "justify" }}>{description}</p>
      </FormGroup>
    </Fragment>
  );
};

export default CornerDistance;
