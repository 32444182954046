import React, { Fragment, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import MathFormula from "../common/MathFormula";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Divider from "../common/Divider";

const CuttingSpeed = () => {
  const [number1, setNumber1] = useState();
  const [number2, setNumber2] = useState();
  const answer = useMemo(() => (12 * number1) / (Math.PI * number2), [
    number1,
    number2,
  ]);
  const speed = useMemo(() => {
    if (isNaN(answer)) return "";
    else return Math.round(answer);
  }, [answer]);

  const formula = '\\text{RPM} = \\frac{12 \\times \\text{Cutting Speed (FPM)}}{\\pi \\times \\text{Diameter (inches)}}';

  return (
    <Fragment>
      <Helmet>
        <title>Cutting Speed Calculator - Integral Software Tools</title>
        <meta
          name="description"
          content="Calculate the cutting speed for a given cutting speed and diameter of stock or cutter. This is used in stock cutting, turning and drilling."
        />
      </Helmet>
      <h1>Cutting Speed Calculator</h1>
      <br />
      <FormGroup>
        <Row className="align-items-center">
          <Col sm="6" className="align-self-center">
            <Label className="m-0" for="number1">
              Cutting Speed in feet/min (FPM)
            </Label>
          </Col>
          <Col sm="6">
            <Input
              autoFocus
              id="number1"
              name="number1"
              type="number"
              className="text-end"
              value={number1}
              onChange={(e) => {
                if (e.target.value === "") {
                  return setNumber1(0);
                }
                setNumber1(e.target.value);
              }}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row className="align-items-center">
          <Col sm="6" className="align-self-center">
            <Label className="m-0" for="number2">
              Diameter of stock or cutter (in inches)
            </Label>
          </Col>
          <Col sm="6">
            <Input
              id="number2"
              name="number2"
              type="number"
              className="text-end"
              value={number2}
              onChange={(e) => {
                if (e.target.value === "") {
                  return setNumber2(0);
                }
                setNumber2(e.target.value);
              }}
            />
          </Col>
        </Row>
      </FormGroup>
      <Divider />
      <Row className="align-items-center">
        <Col sm="6" className="align-self-center">
          <h6>Cutting Speed RPM</h6>
        </Col>
        <Col sm="6">
          <h3 style={{ textAlign: "end" }}>{speed}</h3>
        </Col>
      </Row>
      <FormGroup>
        <p style={{ textAlign: "justify" }}>
          The Cutting Speed Calculator determines the optimal spindle speed
          based on the material’s cutting speed and the diameter of the stock
          (for lathes) or the cutter (for mills). Enter the cutting speed in
          feet per minute (FPM) and the diameter of the stock or cutter. The
          calculator will provide the spindle speed in revolutions per minute
          (RPM).
        </p>
        <MathFormula formula={formula} />
      </FormGroup>
    </Fragment>
  );
};

export default CuttingSpeed;
