import React, { Fragment, useMemo, useState } from "react";
import MathFormula from "../common/MathFormula";
import { Helmet } from "react-helmet";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Divider from "../common/Divider";

const RigidTapping = () => {
  const [number1, setNumber1] = useState();
  const [number2, setNumber2] = useState();
  const calculation = useMemo(() => {
    const result = (number2 * (1 / number1)).toFixed(3);

    if (isNaN(result)) return "";
    else return result;
  }, [number1, number2]);

  return (
    <Fragment>
      <Helmet>
        <title>Rigid Tapping Calculator - Integral Software Tools</title>
        <meta
          name="description"
          content="The Rigid Tapping Calculator helps you determine the feed rate for rigid tapping operations. Enter the number of threads per inch (TPI) and the spindle speed in revolutions per minute (RPM). The calculator will provide the feed rate in inches per minute (IPM)."
        />
      </Helmet>
      <h1>Rigid Tapping Calculator</h1>
      <br />
      <FormGroup>
        <Row className="align-items-center">
          <Col sm="6" className="align-self-center">
            <Label className="m-0" for="number1">
              Threads Per Inch (TPI)
            </Label>
          </Col>
          <Col sm="6">
            <Input
              autoFocus
              id="number1"
              name="number1"
              type="number"
              className="text-end"
              value={number1}
              onChange={(e) => {
                if (e.target.value === "") {
                  return setNumber1(0);
                }
                setNumber1(e.target.value);
              }}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row className="align-items-center">
          <Col sm="6" className="align-self-center">
            <Label className="m-0" for="number2">
              Spindle Speed (RPM)
            </Label>
          </Col>
          <Col sm="6">
            <Input
              id="number2"
              name="number2"
              type="number"
              className="text-end"
              value={number2}
              onChange={(e) => {
                if (e.target.value === "") {
                  return setNumber2(0);
                }
                setNumber2(e.target.value);
              }}
            />
          </Col>
        </Row>
      </FormGroup>
      <Divider />
      <Row className="align-items-center">
        <Col sm="6" className="align-self-center">
          <h6>Calculated Feed Rate (IPM)</h6>
        </Col>
        <Col sm="6">
          <h3 style={{ textAlign: "end" }}>{calculation}</h3>
        </Col>
      </Row>
      <FormGroup>
        <p style={{ textAlign: "justify" }}>
          The Rigid Tapping Calculator helps you determine the feed rate for
          rigid tapping operations. Enter the number of threads per inch (TPI)
          and the spindle speed in revolutions per minute (RPM). The calculator
          will provide the feed rate in inches per minute (IPM).
        </p>
        <MathFormula formula={"\\text{Feed Rate (IPM)} = \\frac{\\text{Spindle Speed (RPM)}}{\\text{Threads Per Inch (TPI)}}"} />
        
      </FormGroup>
    </Fragment>
  );
};

export default RigidTapping;
