import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import Html5QrcodePlugin from "./Html5QrcodePlugin";
import ResultContainerPlugin from "./ResultContainerPlugin";
import "../../assets/scss/style-scanner/qr-scanner.css";

const QrCodeInspector = () => {
  const [decodedResults, setDecodedResults] = useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
    console.log("App [result]", decodedResult);
    setDecodedResults((prev) => [...prev, decodedResult]);
  };

  return (
    <Fragment>
      <Helmet>
        <title>QR Code Inspector - Integral Software Tools</title>
        <meta name="description" content="This tool allows you to scan QR codes and barcodes." />
      </Helmet>
      <section className="App-section">
        <div >
          <h1 className="" >Inspect QR codes and Barcodes</h1>
        </div>
        <p className="App-section-paragraph"> 
        <ul>
          <li>To enable the scanner, please click the "Request Camera Permission" button.</li>
        </ul>
       </p>
        <br />
        <br />
        <br />
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />
        <ResultContainerPlugin results={decodedResults} />
      </section>
    </Fragment>
  );
};

export default QrCodeInspector;
