import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "react-toastify/dist/ReactToastify.min.css";
import "react-datetime/css/react-datetime.css";
import "react-image-lightbox/style.css";
import { ToastContainer, toast } from "react-toastify";
import { CloseButton, Fade } from "./components/common/Toast";
import Home from "./pages/Home";

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route component={Home} />
      </Switch>
      <ToastContainer
        transition={Fade}
        closeButton={<CloseButton />}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Router>
  );
};

export default App;
