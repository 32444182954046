import React, { useState, Fragment } from "react";
import { Helmet } from "react-helmet";


const BoldUnicode = () => {
  const [inputText, setInputText] = useState("");
  const [boldText, setBoldText] = useState("");

  const toBoldUnicode = (inputText) => {
    let boldUnicode = "";
    for (let char of inputText) {
      // Uppercase letters
      if ("A" <= char && char <= "Z") {
        boldUnicode +=
          "&#" + parseInt(char.charCodeAt(0) + 0x1d400 - 0x41) + ";";
      }
      // Lowercase letters
      else if ("a" <= char && char <= "z") {
        boldUnicode +=
          "&#" + parseInt(char.charCodeAt(0) + 0x1d41a - 0x61) + ";";
      }
      // Other characters remain the same
      else {
        boldUnicode += char;
      }
    }
    return boldUnicode;
  };

  const handleChange = (event) => {
    setInputText(event.target.value);
    setBoldText(toBoldUnicode(event.target.value));
  };

  return (
    <Fragment>
      <Helmet>
        <title>Text to Unicode Bold - Integral Software Tools</title>
        <meta name="description" content="This tool allows you to convert text to bold unicode so that you can use for creating impact in your LinkedIn or Twitter updates." />
      </Helmet>
      <h1>Text to Unicode Bold</h1>
      <br />
    <div>
      <p className="App-section-paragraph">
        Enter text you'd like to turn into bold unicode so that you can use for
        creating impact in your LinkedIn or Twitter updates. E.g. If you wanted
        to pop the word unique in this text:
      </p>
      <p className="App-section-paragraph">
        We create 𝐮𝐧𝐢𝐪𝐮𝐞 engagements using AI technology.
      </p>
      <br />
      <input
        type="text"
        value={inputText}
        onChange={handleChange}
        placeholder="Type here..."
      />
      <div className="App-section-paragraph">
        <strong>Output:</strong>
        <div
          dangerouslySetInnerHTML={{ __html: boldText }}
        />
      </div>
    </div>
    </Fragment>
  );
};

export default BoldUnicode;
