import React, { useState, useMemo } from 'react';
import { Helmet } from "react-helmet";
import '../../assets/css/business-loan-calculator.css';

const BusinessLoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [annualInterestRate, setAnnualInterestRate] = useState('');
  const [termInMonths, setTermInMonths] = useState('');

  const calculateLoanDetails = useMemo(() => {
    const P = parseFloat(loanAmount);
    const r = parseFloat(annualInterestRate) / 100 / 12;
    const n = parseInt(termInMonths, 10);

    if (isNaN(P) || isNaN(r) || isNaN(n) || n === 0) return { monthlyPayment: '', totalPayment: '', totalInterest: '' };

    const monthlyPayment = (P * r) / (1 - Math.pow(1 + r, -n));
    const totalPayment = monthlyPayment * n;
    const totalInterest = totalPayment - P;

    return {
      monthlyPayment: monthlyPayment.toFixed(2),
      totalPayment: totalPayment.toFixed(2),
      totalInterest: totalInterest.toFixed(2)
    };
  }, [loanAmount, annualInterestRate, termInMonths]);

  return (
    <div className="loan-calculator">
       <Helmet>
         <title>Business Loan Calculator - Integral Software Tools</title>
         <meta
           name="description"
           content="The Business Loan Calculator helps you determine your monthly payments, total payments, and total interest paid for a business loan. Simply enter the loan amount in dollars, the annual interest rate as a percentage, and the loan term in months. The calculator will provide you with the monthly payment amount, the total amount paid over the life of the loan, and the total interest paid."
         />
       </Helmet>
      <h3>Business Loan Calculator</h3>
      <label>
        Loan Amount ($):
        <input type="number" value={loanAmount} onChange={(e) => setLoanAmount(e.target.value)} />
      </label>
      <br />
      <label>
        Annual Interest Rate (%):
        <input type="number" value={annualInterestRate} onChange={(e) => setAnnualInterestRate(e.target.value)} />
      </label>
      <br />
      <label>
        Term (months):
        <input type="number" value={termInMonths} onChange={(e) => setTermInMonths(e.target.value)} />
      </label>
      <br />
      <div className="loan-details">
        <h4>Loan Details</h4>
        <h3>Your loan will cost...</h3>
        <div className="loan-summary">
          <div className="loan-circle">
            <div className="loan-circle-content">
              {termInMonths} payment(s) of <br />
              <span className="loan-circle-amount">${Number(calculateLoanDetails.monthlyPayment).toLocaleString()}</span>
            </div>
          </div>
          <div className="loan-info">
            <p>Total Payments: ${Number(calculateLoanDetails.totalPayment).toLocaleString()}</p>
            <p>Total Interest: ${Number(calculateLoanDetails.totalInterest).toLocaleString()}</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default BusinessLoanCalculator;